import React from 'react';


window.setInterval(() => {
    document.body.addEventListener('click', function (event) {
        // filter out clicks on any other elements
        if (event.target.nodeName === 'A') {
            event.preventDefault();
        }
    });
}, 1000)

export const Wrap = ({children}) => {

    const [zoom, setZoom] = React.useState(1)
    const zoomRef = React.useRef(zoom);
    const setZoomHandler = data => {
        zoomRef.current = data;
        setZoom(data);
    };

    const [pageX, setPageX] = React.useState(0)
    const pageXRef = React.useRef(zoom);
    const setPageXHandler = data => {
        pageXRef.current = data;
        setPageX(data);
    };

    const [pageY, setPageY] = React.useState(0)
    const pageYRef = React.useRef(zoom);
    const setPageYHandler = data => {
        pageYRef.current = data;
        setPageY(data);
    };


    function preventDefault(e) {
        if (Math.round(zoom*100) !== 100 || e.ctrlKey || e.shiftKey) {
            e.preventDefault();
            if (e.ctrlKey) {
                e.preventDefault()
                let newWheel = Math.sign(e.deltaY) / 100;
                setZoomHandler(zoomRef.current - newWheel)
            } else if (e.shiftKey) {
                setPageXHandler(pageXRef.current - e.deltaY)
            } else {
                setPageYHandler(pageYRef.current - e.deltaY)
            }
        } else {
        }
    }

    function preventDefaultForScrollKeys(e) {
        var keys = {37: 1, 38: 1, 39: 1, 40: 1};
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    React.useEffect(() => {

        var supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; }
            }));
        } catch(e) {}

        var wheelOpt = supportsPassive ? { passive: false } : false;
        var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

        window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);

        var links = document.getElementsByTagName("a");

        [].slice.call(links).forEach((e) => {
            if (e.href) {
                e.href = ""
                e.addEventListener('click', function (event) {
                    event.preventDefault()
                })
            }
        })

        return () => {
            window.removeEventListener('DOMMouseScroll', preventDefault, false); // older FF
            window.removeEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
            window.removeEventListener('touchmove', preventDefault, wheelOpt); // mobile
            window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
        }

    }, [])

    return <div style={{position: "relative", display: "flex", flexGrow: 1, width: "100%"}}>
        <div style={{inset: "0px", overflow: zoom !== 1 ? "hidden" : "unset", margin: "0", padding: "0", width: "100%"}}>
            <div style={{position: "fixed", right: "10px", top: "10px", color: "#0004", opacity: zoom !== 1 ? 1 : 0, transition: "0.3s"}}>{Math.round(zoom * 100)}%</div>
            <style>
                {`html {
                    background-color: #fff;
                    -webkit-font-smoothing: subpixel-antialiased;
                    transform: translate3d(${pageX}px, ${pageY}px, 0px) scale(${zoom});
                    overflow: visible;
                    transform-origin: center 28%;
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    box-shadow: 0 0 12px #6667;
                    border-radius: ${zoom !== 1 ? '6px' : '0'};
                    transition: border-radius 0.3s;
                }
                body {
                    overflow: overlay;
                }
                body {
                    overflow: overlay;
                }
                body::-webkit-scrollbar {
                    transition: 0.3s;
                    background-color: transparent;
                    width: 16px;
                }
                
                /* background of the scrollbar except button or resizer */
                body::-webkit-scrollbar-track {
                    background-color: transparent;
                    border: 4px solid rgba(255,255,255,0);
                    background-clip: padding-box;
                }
                /* scrollbar itself */
                body::-webkit-scrollbar-thumb {
                    transition: 0.3s;
                    background-color: #babac055;
                    border-radius: 16px;
                    border: 4px solid rgba(255,255,255,0);
                    background-clip: padding-box;
                }
                body::-webkit-scrollbar-thumb:hover {
                    background-color: #babac0;
                }
                /* set button(top and bottom of the scrollbar) */
                body::-webkit-scrollbar-button {
                    display:none;
                }
                .\\@pl\\:cmpt: {
                    transition: box-shadow 0.3s;
                }
                .\\@pl\\:selected: {
                    border: 1px solid blue;
                }
                .\\@pl\\:hovered: {
                    box-shadow: inset 0 0 10px 3px #4447!important;
                    border: 1px solid black;
                }
                .\\@pl\\:wrap {
                    display: flex;
                    flex-grow: 1;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
                .\\@pl\\:renderer {
                    display: flex;
                    flex-grow: 1;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
                `}
            </style>
            {children}
        </div>
    </div>

}
