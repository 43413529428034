import React from "react"
import * as Chakra from "@chakra-ui/react"
import {graphql, PageProps, useStaticQuery} from "gatsby"
import FadeTransition from "../components/transitions/fade-transition"
import Header from "../components/header"
import Footer from "../components/footer"
import "keen-slider/keen-slider.min.css"
import "./layout.css"
import "focus-visible/dist/focus-visible";
import { Global, css } from "@emotion/react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"


const Layout = ({ children, location }: PageProps): React.ReactElement => {

    const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaDesc
                        }
                        page {
                            metaDesc
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `)

    const GlobalStyles = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
    }
  `;


  return (
      <SEOContext.Provider value={{ global: seo }}>
        <Global styles={GlobalStyles} />
      <Chakra.Flex minH="100vh" flexDirection="column">
        <Header location={location} />
        <FadeTransition shouldChange={location.pathname}>
          {children}
        </FadeTransition>
        <Footer />
      </Chakra.Flex>
      </SEOContext.Provider>
  )
}

export default Layout
