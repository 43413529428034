const React = require("react")

exports.wrapPageElement = ({ element, props }) => {
    if (props.pageContext.isPressLess) {
        const {BadAccess} = require("./bad-access");
        if (window.self !== window.top) {
            const {Wrap} = require("./wrap");
            return <Wrap>{element}</Wrap>
        }
        return <BadAccess/>
    }
    return element
}
