import React from 'react'
import useScrollPosition from "@react-hook/window-scroll";

export default function useScroll() {

    const scrollY = useScrollPosition(30)

    const lastScrollPositionRef = React.useRef<number>(0)

    let scrollDirection = lastScrollPositionRef.current < scrollY ? "up" : "down"
    lastScrollPositionRef.current = scrollY

    return {
        scrollY,
        scrollDirection,
    }
}
