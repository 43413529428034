import React from 'react'

export const BadAccess = () => {

    return (
        <div style={{flexGrow: 1, width: "100%", height: "100vh", display: "flex", position: "fixed", top: 0, left: 0, zIndex: "100000"}}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
            </style>
            <div style={{position: "absolute", width: "100%", height: "100%", backgroundColor: "#fff"}} />
            <div style={{margin: "auto", padding: "20px"}}>
                <div style={{margin: "auto", borderRadius: "5px", position: "relative", maxWidth: "700px", border: "1px solid #fff3"}}>
                    <div style={{padding: "30px 20px"}}>
                        <p style={{color: "#111", fontFamily: '"Inter", system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif', fontWeight: "400", fontSize: "18px", lineHeight: "20px", letterSpacing: "0", paddingTop: "10px"}}>
                            You've stumbled across one of the template files for this site's PressLess installation - <span style={{opacity: "0.4"}}>The next generation Headless page builder for React.</span>
                        </p>
                        <p style={{color: "#111", fontFamily: '"Inter", system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif', paddingTop: "20px", fontWeight: "400", fontSize: "14px", lineHeight: "14px", letterSpacing: "0"}}>If this is your site, you can make changes to your pages by logging into your WordPress dashboard.</p>
                        <p style={{color: "#111", fontFamily: '"Inter", system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif', paddingTop: "20px", fontWeight: "400", fontSize: "14px", lineHeight: "14px", letterSpacing: "0"}}>Learn more at <a style={{color: "#000", textDecoration: "underline"}} href="https://audallabs.com/pressless">audallabs.com/PressLess</a></p>
                        <small style={{color: "#1119", fontFamily: '"Inter", system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif', paddingTop: "20px", fontWeight: "400", fontSize: "10px", lineHeight: "14px", letterSpacing: "0"}}>©{(new Date()).getFullYear()} Audal Pty Ltd</small>
                    </div>
                </div>
            </div>
        </div>
    )
}
