import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "BeausiteClassic-Medium",
    body: "BeausiteClassic-Clear",
  },
})

export default theme
