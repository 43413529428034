exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-studio-ideas-tsx": () => import("./../../../src/pages/studio/ideas.tsx" /* webpackChunkName: "component---src-pages-studio-ideas-tsx" */),
  "component---src-templates-wp-digital-js": () => import("./../../../src/templates/WpDigital.js" /* webpackChunkName: "component---src-templates-wp-digital-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-plus-js": () => import("./../../../src/templates/WpPlus.js" /* webpackChunkName: "component---src-templates-wp-plus-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "component---src-templates-wp-project-js": () => import("./../../../src/templates/WpProject.js" /* webpackChunkName: "component---src-templates-wp-project-js" */)
}

