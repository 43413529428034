/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { wordpressFlatMenuToHierarchical } from "../utils/wordpress-flat-menu-to-hierarchical"
import FadeTransition from "./transitions/fade-transition"
import RedLogo from "../images/logos/plain-red-logo.svg"
import RedPlusLogo from "../images/logos/red-plus-logo.svg"
import RedDigitalLogo from "../images/logos/red-digital-logo.svg"
import InstagramDot from "../images/instagram-dot.svg"
import FacebookDot from "../images/facebook-dot.svg"
import LinkedInDot from "../images/linkedIn-dot.svg"
import { CustomCollapse } from "./transitions/custom-collapse"
import { GrClose } from "react-icons/gr"
const getScrollbarWidth = require("get-scrollbar-width")
import useScroll from "../utils/use-scroll"

interface MenuProps {
  rootClassName?: string
  button1?: string
  text?: string
  button?: string
  text1?: string
  image_src?: string
  text2?: string
  image_alt?: string
  location?: any
}

const Header = (props: MenuProps) => {
  const data = useStaticQuery(graphql`
    {
      wp {
        siteOptions {
          footerSettings {
            offices {
              emailAddress
              officeName
              phoneNumber
              streetAddress
            }
            socials {
              facebook
              instagram
              linkedin
            }
          }
        }
      }
      wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
        menuItems {
          nodes {
            parentId
            label
            id
            url
          }
        }
      }
    }
  `)

  const { scrollY, scrollDirection } = useScroll()

  const [height, setHeight] = React.useState(97)

  const ref = React.useRef(null)

  const socials = data?.wp?.siteOptions?.footerSettings?.socials

  function wordpressLink(link) {
    return link.endsWith("/") && link.length > 1
        ? link.substring(0, link.length - 1)
        : link
  }

  const parsedHierarchical = React.useMemo(
      () => wordpressFlatMenuToHierarchical(data?.wpMenu?.menuItems?.nodes),
      []
  )
  const { isOpen, onOpen, onClose } = Chakra.useDisclosure()
  const [currentMenuId, setCurrentMenuId] = React.useState("none")
  const [insideBox, setInsideBox] = React.useState(false)
  // const [isDark, setDarkMode] = React.useState<boolean>(
  //   props.location.pathname === "/red-digital" ||
  //     props.location.pathname.includes("/digital")
  // )

  const [isSmallerThan991] = Chakra.useMediaQuery("(max-width: 991px)")

  const [parentHeadingPosition, setParentHeadingPosition] = React.useState(0)

  React.useEffect(() => {
    if (!isOpen) {
      setCurrentMenuId("none")
    }
  }, [isOpen])

  React.useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [props.location.pathname])

  // const darkMode = isDark
  const shouldBeDark =
      props.location.pathname.includes("/red-digital") ||
      props.location.pathname.includes("/digital")

  const { currentFocusedParentIndex, focusedPageName } = React.useMemo(() => {
    let index = null
    let pageName = null

    const walker = (link, menus, parentIndex) => {
      menus.forEach((menuItem, i) => {
        if (wordpressLink(menuItem.url) === link) {
          index = typeof parentIndex === "number" ? parentIndex : i
          pageName = menuItem.label
          return
        } else if (menuItem.childMenu) {
          walker(
              link,
              menuItem.childMenu,
              typeof parentIndex === "number" ? parentIndex : i
          )
        }
      })
    }
    walker(wordpressLink(props.location.pathname), parsedHierarchical, null)

    if (wordpressLink(props.location.pathname).includes("project")) {
      const menuLabelArray = wordpressLink(props.location.pathname)
          .substring(9, props.location.pathname.length)
          .split("-")
      const menuLabel = menuLabelArray.join(" ")
      pageName = menuLabel
      index = 0
    }

    return { currentFocusedParentIndex: index, focusedPageName: pageName }
  }, [props.location.pathname])

  const findCurrentLogo = React.useMemo(() => {
    if (
        currentMenuId ===
        parsedHierarchical.find(
            (item) => item.label.toLowerCase() === "red digital"
        )?.id
    ) {
      return (
          <Chakra.Box width="97px" height="47.42px" objectFit="cover">
            <RedDigitalLogo width="100%" height="100%" />
          </Chakra.Box>
      )
    } else if (
        currentMenuId ===
        parsedHierarchical.find((item) => item.label.toLowerCase() === "red plus")
            ?.id
    ) {
      return (
          <Chakra.Box width="77px" height="45.02px" objectFit="cover">
            <RedPlusLogo width="100%" height="100%" />
          </Chakra.Box>
      )
    } else if (isOpen) {
      return (
          <Chakra.Box
              objectFit="cover"
              width={{ base: "45px", xl: "75px" }}
              style={{ width: scrollY > 50 ? "67.5px" : "75px" }}
          >
            <RedLogo height="100%" width="100%" />
          </Chakra.Box>
      )
    } else if (shouldBeDark) {
      return (
          <Chakra.Box width="97px" height="47.42px" objectFit="cover">
            <RedDigitalLogo width="100%" height="100%" />
          </Chakra.Box>
      )
    } else if (
        props.location.pathname.includes("/red-plus") ||
        props.location.pathname.includes("/plus")
    ) {
      return (
          <Chakra.Box width="77px" height="45.02px" objectFit="cover">
            <RedPlusLogo width="100%" height="100%" />
          </Chakra.Box>
      )
    } else {
      return (
          <Chakra.Box
              objectFit="cover"
              width={{ base: "45px", xl: "75px" }}
              style={{ width: scrollY > 50 ? "67.5px" : "75px" }}
          >
            <RedLogo height="100%" width="100%" />
          </Chakra.Box>
      )
    }
  }, [currentMenuId, props.location.pathname])

  const shouldHideMobileMenu = () => {
    if (isOpen) {
      return ""
    }

    if (scrollY > 50 && scrollDirection !== "down") {
      return "hide"
    }
  }

  return (
      <Chakra.Box
          width="100%"
          display="flex"
          position="sticky"
          top="0"
          alignItems="flex-start"
          flexDirection="column"
          transition="background-color 0.3s, color 0.3s, transform 0.4s"
          zIndex={10}
          color="var(--text-col)"
          backgroundColor="var(--background-col)"
          onMouseLeave={() => {
            // if (shouldBeDark) {
            //   setDarkMode(true)
            // } else {
            //   setDarkMode(false)
            // }
            isOpen && onClose()
            setInsideBox(false)
          }}
          as="header"
          className={`primary-header ${shouldHideMobileMenu()}`}
          css={{
            "&.hide": {
              transform: "translateY(-100%)",
            },
              "@media (max-width: 991px)": {
                backgroundColor: isOpen ? "var(--background-col)!important" : undefined
              }
          }}
      >
        <DropDownContainer
            insideBox={insideBox}
            parentHeadingPosition={parentHeadingPosition}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            setCurrentMenuId={setCurrentMenuId}
            shouldChange={currentMenuId}
            parsedHierarchical={parsedHierarchical}
            setInsideBox={setInsideBox}
            menu={
              currentMenuId !== "none"
                  ? parsedHierarchical.find((x) => x.id === currentMenuId).childMenu
                  : []
            }
            // darkMode={darkMode}
            // setDarkMode={setDarkMode}
            isUnder991={isSmallerThan991}
            location={props.location}
            currentMenuId={currentMenuId}
            socials={socials}
        />
        <Chakra.Box
            zIndex={1}
            display="flex"
            width="100%"
            alignItems="flex-start"
            marginLeft="auto"
            marginRight="auto"
            flexDirection="column"
            transition="background-color 0.3s"
            css={{
              "@media (max-width: 991px)": {
                maxHeight: "100vh",
              },
            }}
        >
          <Chakra.Box
              display="flex"
              width="100%"
              alignItems="flex-start"
              transition="0.3s"
          >
            <Chakra.Box
                width="100%"
                height={"123px"}
                margin="auto"
                display="flex"
                maxWidth="1800px"
                alignItems="flex-start"
                paddingLeft="40px"
                paddingRight="40px"
                flexDirection="row"
                transition="background-color 0.3s ease-out, height 0.3s"
                //bg={isOpen ? "#F4F2ED" : "transparent"}
                css={{
                  "@media (max-width: 991px)": {
                    height: "73px",
                    alignSelf: "flex-start",
                    alignItems: "center",
                    paddingTop: "0px",
                    paddingRight: "40px",
                    justifyContent: "space-between",
                    position: "sticky",
                    top: "0",
                    width: "100vw",
                  },
                  "@media (max-width: 767px)": {
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  },
                }}
            >
              <Chakra.Text
                  display="none"
                  css={{
                    "@media (max-width: 991px)": {
                      display: isOpen ? "block" : "none",
                    },
                  }}
                  width="100%"
                  lineHeight="43px"
              >
                Menu
              </Chakra.Text>
              <Chakra.Box
                  key={currentMenuId}
                  width="318px"
                  height="100%"
                  display="flex"
                  maxWidth="100px"
                  alignSelf="flex-start"
                  alignItems="flex-start"
                  flexShrink="0"
                  transition="padding-top 0.3s"
                  paddingTop={scrollY > 50 ? "24px" : "43px"}
                  zIndex={10}
                  flexDirection="column"
                  css={{
                    "@media (max-width: 991px)": {
                      width: "fit-content",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      paddingTop: "0",
                      display: isOpen ? "none" : "inline-flex",
                    },
                  }}
                  as={Link}
                  to="/"
                  sx={{
                    svg: {
                      width: scrollY > 50 ? "67.5px" : "75px",
                      transition: "0.3s",
                      path: {
                        fill: (!isOpen && !insideBox) ? "var(--hero-text-col)" : "var(--text-col)",
                        transition: "0.3s fill",
                      },
                    },
                  }}
                  onMouseOver={() => onClose()}
              >
                {findCurrentLogo}
              </Chakra.Box>
              <Chakra.Box
                  display="none"
                  width="100%"
                  css={{
                    "@media (max-width: 991px)": {
                      //paddingLeft: "45px",
                      display: "block",
                    },
                    "@media (max-width: 767px)": {
                      paddingLeft: "20px",
                      display: "block",
                    },
                  }}
              >
                {isSmallerThan991 && isOpen ? (
                    <Chakra.Button
                        variant="unstyled"
                        onClick={onClose}
                        color="var(--text-col)"
                        backgroundColor="transparent"
                        py="0"
                        h="43px"
                        css={{
                          svg: {
                            path: {
                              fill: "var(--text-col)",
                              stroke: "var(--text-col)"
                            }
                          }
                        }}
                    >
                      <GrClose />
                    </Chakra.Button>
                ) : (
                    <Chakra.Box
                        as="button"
                        padding="0.5rem 1rem"
                        borderWidth="1px"
                        borderRadius="4px"
                        px="0"
                        textAlign="right"
                        width="100%"
                        color={
                          !isOpen && !insideBox
                              ? "var(--hero-text-col)"
                              : "currentColor"
                        }
                        css={{
                          "@media (max-width: 991px)": {
                            display: "inline-block",
                            lineHeight: "23px",
                            borderWidth: "0px",
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          // setDarkMode(false)
                          !isOpen &&
                          parsedHierarchical[0].childMenu.length >= 1 &&
                          setCurrentMenuId(parsedHierarchical[0].id)
                          isOpen ? onClose() : onOpen()
                        }}
                    >
                      Menu
                    </Chakra.Box>
                )}
              </Chakra.Box>
              <Chakra.Box
                  width="100%"
                  display="flex"
                  flexGrow="1"
                  height="auto"
                  alignItems="flex-start"
                  position="absolute"
                  left="0"
                  bottom="0"
                  top="0"
                  css={{
                    "@media (max-width: 991px)": {
                      display: "none",
                    },
                  }}
              >
                <Chakra.Flex
                    maxWidth="668px"
                    width="100%"
                    marginLeft="auto"
                    marginRight="auto"
                    alignItems="flex-start"
                >
                  {parsedHierarchical.map((menuItem, i) => (
                      <Chakra.Box width="100%" maxWidth="33.333333%" key={i} ref={ref}>
                        <MenuItem
                            index={i}
                            {...menuItem}
                            scrollY={scrollY}
                            currentMenuId={currentMenuId}
                            setCurrentMenuId={setCurrentMenuId}
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                            // darkMode={darkMode}
                            // setDarkMode={setDarkMode}
                            parsedHierarchical={parsedHierarchical}
                            setParentHeadingPosition={setParentHeadingPosition}
                            currentFocusedParentIndex={currentFocusedParentIndex}
                            focusedPageName={focusedPageName}
                            insideBox={insideBox}
                        />
                      </Chakra.Box>
                  ))}
                </Chakra.Flex>
              </Chakra.Box>
            </Chakra.Box>
          </Chakra.Box>
          {/*<Chakra.Box
          height="1px"
          width="100%"
          transition="0.5s"
          backgroundColor={isOpen ? "transparent!important" : "currentColor"}
          className="primary-header-border"
          opacity="0.5"
        />*/}
        </Chakra.Box>
      </Chakra.Box>
  )
}

const DropDownContainer = ({
                             isOpen,
                             onClose,
                             setCurrentMenuId,
                             menu,
                             shouldChange,
                             parsedHierarchical,
                             // setDarkMode,
                             location,
                             isUnder991,
                             parentHeadingPosition,
                             currentMenuId,
                             socials,
                             insideBox,
                             // darkMode,
                             setInsideBox,
                           }) => {
  const [innerContentIsVisible, setInnerContentIsVisible] =
      React.useState(false)

  const onMouseLeave = () => {
    if (!isUnder991) {
      onClose()
      setCurrentMenuId("none")
    }
  }

  return (
      <Chakra.Box
          left="0"
          position="absolute"
          width="100%"
          bottom="0"
          height="fit-content"
          className="drop-down"
          // height={isOpen ? "100%" : "0"}
          color="var(--text-col)"
          backgroundColor="var(--background-col)"
          onMouseLeave={onMouseLeave}
          top="0"
          transformOrigin="top"
          transition="0.3s"
          css={{
            "@media (max-width: 991px)": {
              overflow: "auto",
              top: "73px",
              position: "fixed",
              paddingTop: `${!isOpen && "0"}`,
              paddingBottom: `${!isOpen && "0"}`,
              height: `${isOpen ? "auto" : "0"}`,
            },
          }}
      >
        <Chakra.Box
            as={CustomCollapse}
            unmountOnExit
            in={isOpen}
            endingHeight={isUnder991 ? "100%" : undefined}
            onAnimationComplete={() => {
              if (isOpen) {
                setInnerContentIsVisible(true)
              } else {
                setInnerContentIsVisible(false)
              }
            }}
            onAnimationStart={() => {
              if (!isOpen) {
                setInnerContentIsVisible(false)
              }
            }}
            // background="blue"
            width="100%"
            position="relative"
            css={{
              "@media (max-width: 991px)": {
                overflow: "hidden !important",
                height: `${isOpen ? "100%" : "0"}`,
              },
            }}
        >
          <Chakra.Box
              maxWidth="1800px"
              width="100%"
              m="auto"
              css={{
                "@media (max-width: 991px)": {
                  height: `${isOpen ? "100%" : "0"}`,
                },
              }}
          >
            <Chakra.Box
                // background="red"
                width="100%"
                margin="auto"
                display="flex"
                alignItems="flex-start"
                py="34px"
                paddingLeft="40px"
                paddingRight="40px"
                // flexDirection="row"
                // paddingBottom="50px"
                css={{
                  "@media (max-width: 991px)": {
                    paddingLeft: "0",
                    paddingRight: "0",
                    height: "100%",
                    alignItems: "stretch",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  },
                  "@media (max-width: 767px)": {
                    maxWidth: "100%",
                  },
                }}
            >
              <Chakra.Box
                  width="318px"
                  display="flex"
                  alignItems="flex-start"
                  flexShrink="0"
                  minHeight="378px"
                  flexDirection="column"
                  justifyContent="space-between"
                  position="absolute"
                  css={{
                    "@media (max-width: 991px)": {
                      width: "50%",
                      height: "100%",
                      paddingLeft: "40px",
                      position: "relative",
                    },
                    "@media (max-width: 767px)": {
                      paddingLeft: "20px",
                      paddingBottom: "8px",
                    },
                  }}
                  transition="0.5s"
              >
                <Chakra.Box
                    width="100%"
                    height="auto"
                    display="none"
                    alignItems="flex-start"
                    flexDirection="column"
                    justifyContent="center"
                    css={{
                      "@media (max-width: 991px)": {
                        height: "auto",
                        display: "flex",
                      },
                    }}
                >
                  {parsedHierarchical.map((menuItem) => (
                      <Chakra.Box
                          key={menuItem.id}
                          as={menuItem.url && menuItem.url !== "#" ? Link : "button"}
                          to={
                            menuItem.url && menuItem.url !== "#"
                                ? menuItem.url
                                : undefined
                          }
                          padding="0.5rem 1rem"
                          borderColor="var(--text-col)"
                          borderWidth="1px"
                          borderRadius="4px"
                          transition="0.2s"
                          display="inline-block"
                          opacity={currentMenuId !== menuItem.id ? 0.5 : 1}
                          color="currentColor"
                          position="relative"
                          onMouseEnter={() => {
                            setInsideBox(true)
                          }}
                          _after={{
                            transition: "0.2s",
                            content: "''",
                            position: "absolute",
                            bottom: "3px",
                            left: "0",
                            width: "100%",
                            opacity: currentMenuId !== menuItem.id ? "0" : "1",
                            height: "1px",
                            backgroundColor: "currentColor",
                          }}
                          css={{
                            "@media (max-width: 991px)": {
                              textAlign: "left",
                              fontFamily: "Beausite 400",
                              lineHeight: "26px",
                              paddingTop: "0px",
                              borderWidth: "0px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              paddingBottom: "0px",
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => {
                            if (
                                menuItem.url &&
                                menuItem.url !== "#" &&
                                menuItem.childMenu.length === 0
                            ) {
                              onClose()
                              setCurrentMenuId("none")
                            } else {
                              setCurrentMenuId(menuItem.id)
                              // menuItem.url.includes("/red-digital")
                              //   ? setDarkMode(true)
                              //   : setDarkMode(false)
                            }
                          }}
                      >
                        {menuItem.label}
                      </Chakra.Box>
                  ))}
                </Chakra.Box>
                <Chakra.Box
                    display="block"
                    marginTop="auto"
                    alignItems="flex-start"
                    flexDirection="column"
                    justifyContent="space-between"
                    css={{
                      "@media (max-width: 991px)": {
                        marginTop: "auto",
                        width: "100%",
                        height: "auto",
                      },
                    }}
                >
                  <Chakra.Box
                      display="flex"
                      width="200px"
                      alignItems="flex-start"
                      paddingTop="223px"
                      flexDirection="column"
                      paddingBottom="34px"
                      css={{
                        "@media (max-width: 991px)": {
                          paddingTop: "0px",
                        },
                      }}
                  >
                    <Chakra.Box
                        as="span"
                        fontFamily="BeausiteClassic-Clear"
                        lineHeight="24px"
                        color="currentColor"
                    >
                      Social
                    </Chakra.Box>
                  </Chakra.Box>
                  <Chakra.Box
                      display="flex"
                      width="200px"
                      alignItems="flex-start"
                      flexDirection="column"
                  >
                    {socials?.instagram && (
                        <Chakra.Flex
                            alignItems="center"
                            w="100%"
                            as="a"
                            href={socials?.instagram}
                            target="_blank"
                            transition="0.3s"
                            css={{
                              "&:hover p": {
                                textDecoration: "underline",
                              },
                            }}
                        >
                          <InstagramDot width="9px" height="9px" />
                          <Chakra.Text
                              ml="9px"
                              fontFamily="BeausiteClassic-Clear"
                              lineHeight="24px"
                              color="currentColor"
                          >
                            Instagram
                          </Chakra.Text>
                        </Chakra.Flex>
                    )}
                    {socials?.facebook && (
                        <Chakra.Flex
                            alignItems="center"
                            w="100%"
                            href={socials?.facebook}
                            target="_blank"
                            as="a"
                            transition="0.3s"
                            css={{
                              "&:hover p": {
                                textDecoration: "underline",
                              },
                            }}
                        >
                          <FacebookDot width="9px" height="9px" />
                          <Chakra.Text
                              ml="9px"
                              fontFamily="BeausiteClassic-Clear"
                              lineHeight="24px"
                              color="currentColor"
                          >
                            Facebook
                          </Chakra.Text>
                        </Chakra.Flex>
                    )}
                    {socials?.linkedin && (
                        <Chakra.Flex
                            alignItems="center"
                            w="100%"
                            href={socials?.linkedin}
                            target="_blank"
                            as="a"
                            transition="0.3s"
                            css={{
                              "&:hover p": {
                                textDecoration: "underline",
                              },
                            }}
                        >
                          <LinkedInDot width="9px" height="9px" />
                          <Chakra.Text
                              ml="9px"
                              fontFamily="BeausiteClassic-Clear"
                              lineHeight="24px"
                              color="currentColor"
                          >
                            LinkedIn
                          </Chakra.Text>
                        </Chakra.Flex>
                    )}
                  </Chakra.Box>
                </Chakra.Box>
              </Chakra.Box>

              <Chakra.Box
                  paddingLeft={`${parentHeadingPosition}px`}
                  css={{
                    "@media (max-width: 991px)": {
                      paddingLeft: 0,
                    },
                  }}
                  width="100%"
                  height="100%"
                  transition="0.5s"
              >
                {parsedHierarchical.map((item) => (
                    <Chakra.Box
                        key={item.id}
                        width="100%"
                        height="100%"
                        // display="flex"

                        flexGrow={1}
                        maxWidth="668px"
                        alignSelf="flex-start"
                        minHeight="378px"
                        alignItems="flex-start"
                        borderColor="currentColor"
                        borderWidth="0px"
                        borderTopWidth="0px"
                        borderRightWidth="0px"
                        borderBottomWidth="0px"
                        display={shouldChange === item.id ? "flex" : "none"}
                        css={{
                          "@media (max-width: 991px)": {
                            flexDirection: "column",
                            overflowY: "auto",
                            paddingRight: "40px",
                          },
                          "@media (max-width: 767px)": {
                            paddingRight: "20px",
                            paddingLeft: "15px",
                          },
                        }}
                    >
                      <FadeTransition
                          marginTop="72px"
                          zIndex="100"
                          shouldChange={JSON.stringify(menu)}
                          css={{
                            "@media (max-width: 991px)": {
                              flexDirection: "column",
                              marginTop: "0",
                            },
                          }}
                      >
                        <Menus
                            item={item}
                            innerContentIsVisible={innerContentIsVisible}
                            onClose={onClose}
                            isMobile={isUnder991}
                        />
                      </FadeTransition>
                    </Chakra.Box>
                ))}
              </Chakra.Box>
            </Chakra.Box>
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
  )
}

const Menus = ({ innerContentIsVisible, item, onClose, isMobile }) => {
  const hasSubChildren = (
      item?.childMenu?.map((x) => x.childMenu).flat().length > 0
  )

  if (hasSubChildren) {
    return (
        <>
          {item.childMenu &&
          item.childMenu.map((subItem) => (
              <Chakra.Box
                  key={subItem.label}
                  width="33.333333%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  transition="0.3s"
                  opacity={innerContentIsVisible ? 1 : 0}
                  css={{
                    "@media (max-width: 991px)": {
                      paddingBottom: "35px",
                      width: "100%",
                        paddingRight: "15px"
                    },
                  }}
              >
                <Chakra.Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    paddingBottom="34px"
                >
                  <Chakra.Box
                      fontSize="16px"
                      fontFamily="BeausiteClassic-Clear"
                      lineHeight="27px"
                      as={subItem.url && !subItem.url.includes("#") ? Link : "span"}
                      to={
                        subItem.url && !subItem.url.includes("#")
                            ? subItem.url
                            : undefined
                      }
                      onClick={() => {
                        if (isMobile) {
                          onClose()
                        }
                      }}
                      position="relative"
                  >
                    {subItem.label}
                  </Chakra.Box>
                </Chakra.Box>
                <Chakra.Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                >
                  {subItem?.childMenu &&
                  subItem.childMenu.map((subMenu) => (
                      <Chakra.Box
                          key={subMenu.label}
                          fontSize="16px"
                          fontFamily="Beausite 400"
                          lineHeight="27px"
                          as={Link}
                          position="relative"
                          to={subMenu.url === "#" ? undefined : subMenu.url}
                          cursor={subMenu.url === "#" ? "not-allowed" : "pointer"}
                          opacity={subMenu.url === "#" ? 0.5 : 1}
                          onClick={() => {
                            onClose()
                          }}
                          _hover={{
                            "&:after": {
                              opacity: `${subMenu.url === "#" ? 0 : 1}`,
                            },
                          }}
                          _after={{
                            transition: "0.1s",
                            content: "''",
                            position: "absolute",
                            left: "0",
                            width: "100%",
                            opacity: "0",
                            height: "1px",
                            bottom: "0",
                            backgroundColor: "currentColor",
                          }}
                      >
                        {subMenu.label}
                      </Chakra.Box>
                  ))}
                </Chakra.Box>
              </Chakra.Box>
          ))}
        </>
    )
  }

  return (
      <>
        {
          <Chakra.Box
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              onClick={() => {
                if (isMobile) {
                  onClose()
                }
              }}
          >
            {item?.childMenu &&
            item?.childMenu.map((subMenu) => (
                <Chakra.Box
                    key={subMenu.label}
                    fontSize="16px"
                    fontFamily="Beausite 400"
                    lineHeight="24px"
                    as={Link}
                    to={subMenu.url}
                    color="currentColor"
                    position="relative"
                    onClick={() => {
                      onClose()
                    }}
                    _hover={{
                      "&:after": {
                        opacity: "1",
                      },
                    }}
                    _after={{
                      transition: "0.1s",
                      content: "''",
                      position: "absolute",
                      left: "0",
                      width: "100%",
                      opacity: "0",
                      height: "1px",
                      bottom: "0",
                      backgroundColor: "currentColor",
                    }}
                >
                  {subMenu.label}
                </Chakra.Box>
            ))}
          </Chakra.Box>
        }
      </>
  )
}

const MenuItem = ({
                    label,
                    url,
                    childMenu,
                    currentMenuId,
                    id,
                    setCurrentMenuId,
                    isOpen,
                    onOpen,
                    onClose,
                    // setDarkMode,
                    parsedHierarchical,
                    setParentHeadingPosition,
                    currentFocusedParentIndex,
                    focusedPageName,
                    index,
                    insideBox,
                    scrollY,
                  }) => {
  const typeProps: any = {}

  const ref = React.useRef()

  const findParentHeadingPosition = () => {
    if (ref.current && typeof window !== "undefined") {
      const dims = ref.current.getBoundingClientRect()
      const pageWidth = window.innerWidth
      const currentBrowserScrollbarWidth = getScrollbarWidth()
      if (pageWidth <= 1800) {
        setParentHeadingPosition(dims.x - 40)
      } else {
        const reX = dims.x - (pageWidth - 1800) / 2 - 40
        setParentHeadingPosition(reX + currentBrowserScrollbarWidth / 2)
      }
    }
  }

  if (childMenu.length === 0) {
    typeProps.to = url
    typeProps.as = Link
    typeProps.onClick = () => {
      isOpen && onClose()
      setCurrentMenuId("none")
    }
    typeProps.onMouseEnter = () => {
      //  url.includes("/red-digital") || url.includes("/digital")
      //   ? setDarkMode(true)
      //   : setDarkMode(false)
      setCurrentMenuId(id)
      isOpen && onClose()
      setTimeout(() => setCurrentMenuId(id), 10)
    }
    typeProps.onMouseLeave = () => {
      setCurrentMenuId("none")
      isOpen && onClose()
    }
  } else {
    typeProps.as = "button"
    if (url && url !== "#") {
      typeProps.to = url
      typeProps.as = Link
      typeProps.onClick = () => {
        isOpen && onClose()
        setCurrentMenuId("none")
      }
    }
    typeProps.onMouseOver = () => {
      setCurrentMenuId(id)
      // url.includes("/red-digital") || url.includes("/digital")
      // ? setDarkMode(true)
      // : setDarkMode(false)
      findParentHeadingPosition()
      !isOpen && onOpen()
    }
    //typeProps.onMouseLeave = () => { setCurrentMenuId("none")  }
  }

  const getHeaderLinkOpacity = () => {
    if (!isOpen) {
      return 1
    }
    return currentMenuId !== id && currentFocusedParentIndex !== index ? 0.5 : 1
  }

  return (
      <Chakra.Link
          ref={ref}
          transition="padding-top 0.3s"
          marginTop={scrollY > 50 ? "24px" : "43px"}
          marginBottom={scrollY > 50 ? "24px" : "43px"}
          {...typeProps}
          _focus={{ outline: "none" }}
          marginRight="5%"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          color={!isOpen && !insideBox && "var(--hero-text-col)"}
          onClick={() => {
            parsedHierarchical.find((x) => x.id === id).childMenu.length >= 1
                ? onOpen()
                : onClose()
            setCurrentMenuId(id)
          }}
          textDecoration="none"
          _hover={{
            textDecoration: "none",
          }}
      >
        <Chakra.Box
            as="span"
            fontSize="18px"
            fontFamily="BeausiteClassic-Clear"
            lineHeight="23px"
            // opacity={1}

            opacity={getHeaderLinkOpacity()}
            color="currentColor"
            position="relative"
            // _after={{
            //   transition: "0.4s",
            //   content: "''",
            //   position: "absolute",
            //   bottom: "0",
            //   left: "0",
            //   width: currentMenuId !== id ? "0%" : "100%",
            //   height: "1px",
            //   backgroundColor: "currentColor",
            // }}
            transition="color 0.3s"
        >
          {label}
        </Chakra.Box>
        <Chakra.Box
            as="span"
            fontSize="15px"
            fontFamily="BeausiteClassic-Clear"
            lineHeight="23px"
            color="currentColor"
            position="relative"
            transition="0.3s"
            paddingTop="3px"
            textTransform="none"
            textAlign="left"
            opacity={
              currentFocusedParentIndex === index &&
              focusedPageName !== label &&
              !isOpen
                  ? 1
                  : 0
            }
        >
          {focusedPageName === "petersham rsl" ? "Petersham RSL" : focusedPageName}
        </Chakra.Box>
      </Chakra.Link>
  )
}

export default Header
