import React, { useState } from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Footer = (): React.ReactElement => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = React.useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    let data = {}
    const result = await addToMailchimp(email, data)

    setIsLoading(false)
    setEmail("")
  }

  const data = useStaticQuery(graphql`
    {
      wp {
        siteOptions {
          footerSettings {
            offices {
              emailAddress
              officeName
              phoneNumber
              streetAddress
            }
            socials {
              facebook
              instagram
              linkedin
            }
          }
        }
      }
    }
  `)

  const socials = data?.wp?.siteOptions?.footerSettings?.socials
  const offices = data?.wp?.siteOptions?.footerSettings?.offices

  return (
    <>
      <Chakra.Box
        height="1px"
        width="100%"
        backgroundColor="currentColor"
        opacity="0.5"
      />
      <Chakra.Box
        width="100%"
        display="flex"
        position="relative"
        alignItems="flex-start"
        flexDirection="column"
        backgroundColor="transparent"
      >
        <Chakra.Box
          width="100%"
          display="flex"
          position="relative"
          alignItems="flex-start"
          flexDirection="column"
          backgroundColor="transparent"
        >
          <Chakra.Box
            width="100%"
            display={{base:'grid', xl:'flex'}}
            gridGap="30px"
            maxWidth="1800px"
            alignItems="stretch"
            marginLeft="auto"
            paddingTop="32px"
            marginRight="auto"
            paddingLeft="40px"
            paddingRight="40px"
            paddingBottom="32px"
            justify={{base:'start', xl:'space-between'}}
            gridTemplateColumns="repeat(4,1fr)"
            css={{
              "@media (max-width: 991px)": {
                gridTemplateColumns: "repeat(2,1fr)",
              },
              "@media (max-width: 767px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
              "@media (max-width: 479px)": {
                gridTemplateColumns: "repeat(1,1fr)",
              },
            }}
          >
            <Chakra.Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              fontFamily="Beausite 400"
              flexDirection="column"
              paddingBottom="0px"
            >
              <Chakra.Box
                fontSize="18px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="24px"
                paddingBottom="40px"
              >
                Join Us
              </Chakra.Box>

              <form
                onSubmit={handleSubmit}
                name="Footer Form"
                style={{ width: "100%" }}
              >
                <Chakra.Input
                    key={(typeof window !== "undefined").toString()}
                  borderColor="transparent"
                  fontSize="16px"
                  width="100%"
                  padding="0px !important"
                  _focus={{ borderColor: "transparent" }}
                  _hover={{ borderColor: "transparent" }}
                  fontFamily="BeausiteClassic-Clear"
                  lineHeight="26px"
                  placeholder="Email address"
                  value={email}
                  name="email"
                  borderRadius="0"
                  height="auto"
                  onChange={(e) => setEmail(e.target.value)}
                  _placeholder={{
                    fontSize: "16px",
                    padding: "0px",
                    color: "currentColor",
                    opacity: 0.5,
                  }}
                  border="none"
                  required
                />
                <Chakra.Button
                  type="submit"
                  height="auto"
                  fontSize="16px"
                  lineHeight="26px"
                  marginBottom="5px"
                  fontWeight="400"
                  bg="transparent"
                  transition="0.3s"
                  _hover={{
                    "&:after": {
                      opacity: "1",
                    },
                  }}
                  _after={{
                    transition: "0.1s",
                    content: "''",
                    position: "absolute",
                    left: "0",
                    width: "100%",
                    opacity: "0",
                    height: "1px",
                    bottom: "0",
                    backgroundColor: "currentColor",
                  }}
                  _focus={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  padding="0px !important"
                  isLoading={isLoading}
                >
                  Submit
                </Chakra.Button>
              </form>
              <Chakra.Box
                mt="auto"
                width="100%"
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Chakra.Box
                  as="span"
                  fontSize="16px"
                  fontStyle="normal"
                  fontFamily="Beausite 400"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  Red. {new Date().getFullYear()}
                </Chakra.Box>
              </Chakra.Box>
            </Chakra.Box>
            {socials && (
              <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                fontFamily="Beausite 400"
                flexDirection="column"
                paddingBottom="0px"
                justifySelf={{base:'start', xl:'center'}}
                maxWidth={{base:'100%', xl:'320px'}}
              >
                <Chakra.Box
                  as="span"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="24px"
                  paddingBottom="30px"
                >
                  Social
                </Chakra.Box>
                <Chakra.Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  //mt="auto"
                >
                  <Chakra.Link
                    fontSize="16px"
                    fontStyle="normal"
                    fontFamily="Beausite 400"
                    fontWeight="400"
                    lineHeight="24px"
                    target="_blank"
                    href={socials.instagram}
                  >
                    Instagram
                  </Chakra.Link>
                  <Chakra.Link
                    fontSize="16px"
                    fontStyle="normal"
                    fontFamily="Beausite 400"
                    fontWeight="400"
                    lineHeight="24px"
                    target="_blank"
                    href={socials.facebook}
                  >
                    Facebook
                  </Chakra.Link>
                  <Chakra.Link
                    fontSize="16px"
                    fontStyle="normal"
                    fontFamily="Beausite 400"
                    fontWeight="400"
                    lineHeight="24px"
                    target="_blank"
                    href={socials.linkedin}
                  >
                    LinkedIn
                  </Chakra.Link>
                </Chakra.Box>
              </Chakra.Box>
            )}
            {offices &&
              offices.map((office, index) => (
                <Chakra.Box
                  key={index}
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  fontFamily="Beausite 400"
                  flexDirection="column"
                  paddingBottom="0px"
                  justifySelf={{base:'start', xl:'center'}}

                  maxWidth={{base:'100%', xl: index == 0 ? '320px': '100%'}}
                >
                  <Chakra.Box
                    as="span"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="24px"
                    paddingBottom="30px"
                  >
                    {office?.officeName}
                  </Chakra.Box>
                  <Chakra.Box
                    width="100%"
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    //mt="auto"
                  >
                    <Chakra.Box
                      as="a"
                      target="_blank"
                      fontSize="16px"
                      fontStyle="normal"
                      fontFamily="Beausite 400"
                      fontWeight="400"
                      lineHeight="24px"
                      href={`https://maps.google.com/?q=${office?.streetAddress.replace(
                        /<[^>]*>?/gm,
                        ""
                      )}`}
                      dangerouslySetInnerHTML={{
                        __html: office?.streetAddress,
                      }}
                    />
                    <Chakra.Box
                      as="span"
                      fontSize="16px"
                      fontStyle="normal"
                      fontFamily="Beausite 400"
                      fontWeight="400"
                      lineHeight="24px"
                    >
                      {office?.email}
                    </Chakra.Box>
                    <Chakra.Box
                      as="a"
                      fontSize="16px"
                      fontStyle="normal"
                      fontFamily="Beausite 400"
                      fontWeight="400"
                      lineHeight="24px"
                      href={`tel:${office?.phoneNumber}`}
                    >
                      {office?.phoneNumber}
                    </Chakra.Box>
                  </Chakra.Box>
                </Chakra.Box>
              ))}
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
    </>
  )
}

export default Footer
